<template>
  <b-container v-touch:swipe.left="swipeLeft">
    <b-row>
      <b-col cols="1"/>
        <b-col>
        <h2>Fahrrad fahren, Fahrrad leihen</h2>
        </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img src="@/assets/illustrations/bikergreenshirt.svg" class="illustration" alt="biker" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
          <p>Du kannst dir ein Fahrrad leihen oder dein eigenes Fahrrad verleihen. Auf der ersten Seite wählst du den Modus.</p>
          <p style="margin-bottom: 10rem; ">Wenn du dein Fahrrad anderen zur Verfügung stellst, dann bekommst du Zeit Credits, die du bei deinem Zeitlimit einsetzen kannst.</p>
      </b-col> 
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="appTutorial" :max="6" :pos="1"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
     <b-row>
      <NextButton text="Weiter" next @click="$router.push('/apptutorial/2')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'AppTutorial1',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/appTutorial/2')
      }
    }
  }
</script>

<style scoped>
.illustration {
  pointer-events: none;
}
</style>